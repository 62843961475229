import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "city-text.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className="hero-applications">
            <div className="container">
                <div className='column'>
                    <div>
                    <div className="main-text">
                        Digital <span>twin.</span>
                    </div>
                    <div className="sub-text">
                        Een virtuele kopie van de reststromen in de stad. 
                    </div>
                    </div>
                
                <div className='child'>
                <p>De digital twin van de reststromen is een virtuele kopie die met behulp van data en wiskundige modellen de dynamica van de productie, 
                    inzameling en verwerking van reststromen in kaart brengt.</p>
                </div>
                <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className='child'>
                <h3> Waste intelligence. </h3>
                <p> Met de inzichten uit onze digital twin zijn bedrijven en bewoners, 
                    inzamelaars en verwerkers altijd op de hoogte van welke reststroom waar en wanneer verwacht wordt. Op deze manier
                    kunnen ze hun processen optimaal op elkaar afstemmen. </p> 
                </div>
                <div className='child right'>
                <h3> Data-gedreven efficiëntie. </h3>
                <p>Wetende welk afval waar geproduceerd wordt en waar het verwerkt zou kunnen worden, kun je met onze state-of-the-art 
                    optimalisatie modellen de meest optimale manier van inzamelen en verwerken bepalen. </p>
                </div>
                <div className='child'>
                <h3> De beste valorisatie. </h3>
                <p>Via welke route kun je deze reststroom het beste naar de verwerker brengen? Welke verwerker kies je? 
                    Hoe waarborg je de kwaliteit van de reststroom het beste? Onze tools geven je 
                    een compleet inzicht in de valorisatie van de afvalstromen en de impact op het milieu. </p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Hero

